import React, {
    useRef,
    useState,
    useEffect,
    createContext,
    useContext,
  } from "react"
  import CalendarGfg from "../components/Common/celander"
  import info_icon from "../images/info_icon.svg"
  import SelectService from "../components/Settings/SelectService";
  import Select from "react-tailwindcss-select";
  import SelectSeries from "../components/Settings/SelectSeries";
  import Sidebar from "../components/Common/Sidebar"
  import Header from "../components/Common/Header"
  import bike_img from "../images/bike_img.png"
  import placeholder_img from "../images/camera_img.svg"
  import SurveyForm from "../components/Service/Surveyform"
  import { useNavigate } from "react-router-dom"
  import axios from "axios"
  import Modal from 'react-modal';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from "react-i18next";


function History_button() {
    const storedItem = localStorage.getItem('selectedLanguage');
    const [warrantyModal, setWarrantyModal] = useState(false);
    const handleClick6 = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`);
        const data = await response.json();
        if (data.length > 0) {
          // Navigate to a different route if response length is greater than 0
          toast.error('Warranty Registration is already submitted against this product', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          
        } else {
          // Show modal if response length is 0
          navigate("/vehicleData/warrantyregisterartion");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error here
      }
    };
  
    // Parsing the retrieved item as a JSON object
    const parsedItem = JSON.parse(storedItem);
    
    // Accessing the 'label' property of the parsed object
    const label = parsedItem.label;
    
    console.log("require",label);
    const {t}=useTranslation();
    const navigate = useNavigate();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [buttonStyle, setButtonStyle] = useState({});
    React.useEffect(() => {
      // console.log("data=--_>> ",localStorage.getItem("vinnData"))
      if(!localStorage.getItem("vinnData")){
        navigate("/vinn")
      }
      else{
        getApiData();
      }
    
    }, [])
    const data = localStorage.getItem("vinnData");
    const parsed_data = JSON.parse(data);
    const [showModal, setShowModal] = useState(false);
   
  
    function downloadPdfFile(pdfUrl) {
      // Create an anchor element
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'your_file_name.pdf'; // Set the desired file name here
    
      // Trigger a click event on the anchor element
      link.click();
    }
    const fetchDataByLanguage = (language) => {
      setShowModal2(false);
  
      // Construct the API URL using the selected language and db_data
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.id}/`;
  
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log("Fetched data:", data);
          if (data.service_manual_pdf) {  // Check if data is not null and user_manual_pdf exists
            // Download the PDF file
            downloadPdfFile(`${data.service_manual_pdf}`);
          } else {
            toast.error('No Service manual found', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          alert('Error fetching data. Please try again later.');
        });
  };
  const fetchDataByLanguageusermanual = (language) => {
    setshowModalusermanual(false);
  
    // Construct the API URL using the selected language and db_data
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_user_manual/${language}/${db_data?.id}/`;
  
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data);
        if (data.user_manual_pdf) {  // Check if data is not null and user_manual_pdf exists
          // Download the PDF file
          downloadPdfFile(`${data.user_manual_pdf}`);
        } else {
          toast.error('No user manual found', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please try again later.');
      });
  };
    // const fetchDataByLanguage = (language) => {
    //   setShowModal2(false)
  
    //   // Construct the API URL using the selected language and db_data
    //   const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_service_manual/${language}/${db_data?.id}/`;
    //   // setShowModal(false);
    //   // Make the API call using the apiUrl
    //   fetch(apiUrl)
    //     .then((response) => {
    //       if (!response.ok) {
    //         // Handle the case where the API request fails
    //         throw new Error('Network response was not ok');
    //       }
    //       return response.json();
    //     })
    //     .then((data) => {
    //       if (data?.Service_manual_pdf) {
    //         // Handle the API response data here
    //         console.log(data);
            
  
    //         downloadPdfFile(`${process.env.REACT_APP_API_URL}/${data?.Service_manual_pdf}`)
    //       } else {
    //         // If data is empty, show an alert
    //         toast.error('No Service manual found', {
    //           position: "top-right",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "colored",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching data:', error);
    //       // Handle any other errors that occur during the request
    //       alert('Error fetching data. Please try again later.');
    //     });
    // };
    
    
  
  
   
    function formatTimestamp(timestamp) {
      const dateObj = new Date(timestamp);
  
      const dateStr = dateObj.toLocaleDateString("en-US");
      const parts = dateStr.split('/');
      const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
      const timeStr = dateObj.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: false });
  
      return `${formattedDate} ${timeStr}`;
    }
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const handleLanguageSelect = (language) => {
      setSelectedLanguage(language);
    };
    const [hasWarrantyRegistration, setHasWarrantyRegistration] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);  // Define the isLoaded state
    const db_data = parsed_data?.data;
    const [db_data_history, setDb_Data] = useState(null);
    const dateStr = db_data?.date_of_manufacture;
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    const getApiData = async () => {
      console.log("db_data+++++>> ",db_data)
      const res = await axios.get(
        // `${process.env.REACT_APP_API_URL}/products/${db_data?.sku}/history/`
        `${process.env.REACT_APP_API_URL}/products/${db_data?.vin_code}/history/`
      );
      console.log("Response=====> ",res?.data)
      if (res?.data?.length) {
        // const reversedData = res?.data;
        const reversedData = [...res.data].reverse();
        setDb_Data(reversedData);
      }
        
    };
    React.useEffect(() => {
      getApiData();
    }, []);
    //show history
    const [showHistory, setShowHistory] = useState(false);
  
    const toggleHistory = () => {
      setShowHistory(!showHistory);
    };
  
    const historyRef = React.useRef(null);
  
    React.useEffect(() => {
      if (showHistory) {
        // Scroll to the history section when it's shown
        historyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [showHistory]);
  
  
  
  
  
    // HISTORY LIST DATA
    // const items = [
    //   {
    //     length: 1,
    //     timestamp: "2022-01-05 16:00",
    //     description: "A vehicle ordered from the factory",
    //   },
    //   {
    //     length: 2,
    //     timestamp: "2022-03-02 8:30",
    //     description:
    //       "Vehicle registered in the system and sent out from the factory",
    //   },
    //   {
    //     length: 3,
    //     timestamp: "2022-04-11 11:18",
    //     description: "Transport from China - to Estonia",
    //   },
    //   {
    //     length: 4,
    //     timestamp: "2022-04-15 13:45",
    //     description: "Arrived in Motohobi warehouse",
    //   },
    //   {
    //     length: 5,
    //     timestamp: "2022-04-15 16:45",
    //     description: "Motorcycle assembly Ants Käbi, Motohobi",
    //   },
    //   {
    //     length: 6,
    //     timestamp: "2022-04-17 17:00",
    //     description:
    //       "Sales Contract, Motorcycle Awarded to the User Matti Kasela",
    //   },
    //   {
    //     length: 7,
    //     timestamp: "2022-08-20 12:15",
    //     description:
    //       "1000km maintenance reservation, spare parts ordered. Maintenance time 2022-08-27",
    //   },
    // ];
    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`);
          const data = await response.json();
          setHasWarrantyRegistration(data.length > 0);
          setIsLoaded(true); // Set loading state to true when data fetching is complete
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error here
        }
      };
    
      // Initialize loading state to false
      setIsLoaded(false);
    
      fetchData();
    }, [db_data?.id]);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalusermanual, setshowModalusermanual] = useState(false);
    const handleDownload = () => {
      const fileId = '1mLuFXX6s-_brFA8tCZCCovhO0-vfEKxD'; // Replace with your Google Drive file ID
      const url = `https://drive.google.com/file/d/16ShLrViH0V2ggHjso_ym_yWY6lJWTssu/view`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const handleButtonClick = async () => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/bulletins/${db_data.vin_code}/`);
          
          if (response.data.length > 0 /* Check if data is as expected */) {
              // Store data in local storage
              localStorage.setItem('bulletinData', JSON.stringify(response.data));
              console.log(response.data)
              // Change button color if data is present
              setButtonStyle({border: '1px solid #009bb4', color: 'red'});
              
              // Navigate to '/service/maintenance'
              navigate('/service/bulletin');
          } else {
              // Show alert if no data
              toast.error('No data found', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          alert('Error fetching data');
      }
  };
  
  
    const handleDownloadone = () => {
      const fileId = '1AZSDXHKkJWcB4W34CIUI5gUne_gfQ1Fu'; // Replace with your Google Drive file ID
      const url = `https://drive.google.com/file/d/1nLsQHMeheszp8UTU2I35BFT9vGhnOWC_/view`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'my-custom-name.pdf'); // Set your desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const handleClick = (description) => {
      if (description.includes('Assembling made')) {
        navigate('/vehicleData/displayassembling');
      } else if (description.includes('Warranty registration made')) {
        navigate('/vehicleData/displaywarrantyregister');
      }
    };
    // // Reverse the order of the items array
    // items.reverse();
    const image_url = `${db_data?.image}`
  return (
    <div className="flex">
    <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

    <Header setOpenSidebar={setOpenSidebar} />

    <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
      {/* VEHICLE DATA */}
     
      <div className="your-wrapper-class" style={{ padding: '20px' }}>
            <h1 className="text-f_20_l_25 xl:text-f_33_l_42 font-supremeBold cursor-pointer">
            {t("History")}
            </h1>
        </div>
          
      <ul>
                {db_data_history?.map((item, index) => (
                  <li
                    className={
                      index % 2 === 0
                        ? "flex item-center pl-5 pr-1.5 py-3.5 md:px-[30px] md:py-5 xl:py-[30px] bg-black-400 cursor-pointer"
                        : "flex item-center pl-5 pr-1.5 py-3.5 md:px-[30px] md:py-5 xl:py-[30px] bg-black-500 cursor-pointer"
                    }
                    key={index}
                    onClick={() => handleClick(item.description)}
                  >
                    {/* LENGTH */}
                    <div className="flex items-center md:items-baseline justify-start min-w-[32px] md:min-w-[46px] xl:min-w-[52px] text-f_15_l_19 md:text-f_14_l_18 xl:text-f_18_l_23">
                      {/* {index + 1} */}
                      {db_data_history.length - index}
                    </div>
                    <div className="flex flex-col md:flex-row text-f_11_l_14 md:text-f_14_l_18 xl:text-f_18_l_23">
                      {/* TIMESTAMP */}
                      <div className="mb-3 md:mb-0 min-w-[180px] xl:min-w-[217px]">
    {formatTimestamp(item.timestamp)}
  </div>
                      {/* DESCRIPTION */}
                      <div className="ml-5 md:mb-0 min-w-[180px] xl:min-w-[217px]">
  {/* Add additional classes for mobile font size */}
  <div className="text-base md:text-base xl:text-xl">
    {label === 'Estonian' ? item.estonian_description :
      label === 'Latvian' ? item.latvian_description :
        label === 'Finnish' ? item.finnish_description :
          item.description}
  </div>
</div>
                    </div>
                  </li>
                ))}
              </ul>
    </main>
  </div>
  )
}

export default History_button