import React, { useState } from "react";
import { TfiZoomIn } from "react-icons/tfi";
import { TfiZoomOut } from "react-icons/tfi";
import PropTypes from "prop-types";

const ImageModal = ({ imageUrl, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(1); // Default zoom level is 1

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2)); // Max zoom level of 3
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2)); // Min zoom level of 0.5
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-background") {
      onClose();
    }
  };
  return (
    <div
      id="modal-background"
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75 backdrop-blur-sm"
      style={{ transform: `scale(${zoomLevel})`, transformOrigin: "center" }}
      onClick={handleOutsideClick}
    >
      <div className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg max-w-full max-h-full md:max-w-[60%] md:max-h-[80%] overflow-auto">
        <div className="flex items-center justify-center gap-2 h-full">
          <button
            className="p-2 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={handleZoomOut}
          >
            <TfiZoomOut color="black" />
          </button>
          <button
            className="p-2 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={handleZoomIn}
          >
            <TfiZoomIn color="black" />
          </button>
        </div>
        <button
          className="absolute top-2 right-2 text-red-500 text-2xl font-bold hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 rounded-full"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex items-center justify-center h-full">
          <img
            src={imageUrl}
            alt="Modal content"
            className="md:h-[60vh] block max-w-full max-h-full"
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageModal;
