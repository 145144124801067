import React, {
    useRef,
    useState,
    useEffect,
    createContext,
    useContext,
  } from "react"
  // import {  toast } from 'react-toastify';
  // import CalendarGfg from "../components/Common/celander"
  import info_icon from "../images/info_icon.svg"
  import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
  import SelectService from "../components/Settings/SelectService";
  import Select from "react-tailwindcss-select";
  import SelectSeries from "../components/Settings/SelectSeries";
  import Sidebar from "../components/Common/Sidebar"
  import Header from "../components/Common/Header"
  import bike_img from "../images/bike_img.png"
  import placeholder_img from "../images/camera_img.svg"
  import SurveyForm from "../components/Service/Surveyform"
  import { useNavigate } from "react-router-dom"
  import axios from "axios"
  import Modal from 'react-modal';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from "react-i18next";


function Addwork() {
    const {t}=useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = useState(new Date());
    const [openSidebar, setOpenSidebar] = useState(false);
    const [selectedCards, setSelectedCards] = useState([]);
    const data = localStorage.getItem("vinnData")
    const [showModal, setShowModal] = useState(false);
    const [selectedMileage, setSelectedMileage] = useState(null);
    const profileId = +localStorage.getItem("profile_id");
    const parsed_data = JSON.parse(data)
    const db_data = parsed_data?.data
    const vin=localStorage.getItem("vinnData")
    const [mileageOptions, setMileageOptions] = useState([]);
    const storedItem = localStorage.getItem('bulletinData');
    console.log("86756tjjgtut768============",storedItem)
    const product_id = db_data.vehicle_id
    const dateStr = db_data?.date_of_manufacture;
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    console.log("db_data+++++>> ",db_data)
    const image_url = `${db_data?.image}`
    // const [openSidebar, setOpenSidebar] = useState(false)
   
   
    ///////
  
    
    const [dataPost, setDataPost] = useState({
   
        
       
       
        product:db_data.id,
        user: profileId
      },
      
    );
    const saveWork = async () => {
      console.log("=================+++++", dataPost);
      console.log("=================+++++", dataPost.length);
      // Check if any of the fields are empty
      if (dataPost.length == 7) {
          // Show error message if any field is empty
          toast.error(`Please fill all fields`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
          return; // Stop execution if any field is empty
      }
  
      // All fields are filled, proceed with API call
      try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/add_work_record/`, dataPost);
  
          if (response.status === 201) {
              // Call another function upon successful API call
              toast.success(`Work created successfully`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
  
              // History API call
              const formdata = new FormData();
              formdata.append(
                  "description",
                  ` Work has been created successfully `
              );
              formdata.append(
                  "estonian_description",
                  ` Töö on edukalt loodud. `
              );
              formdata.append(
                  "latvian_description",
                  ` Darbs ir veiksmīgi izveidots `
              );
              formdata.append(
                  "finnish_description",
                  ` Työ on luotu onnistuneesti `
              );
              formdata.append("historical_note", ``);
              formdata.append("vehicle", db_data?.id);
              formdata.append("owner_email", db_data?.owner_email);
              formdata.append("plate_number", db_data?.plate_number);
              formdata.append("vin_code", db_data?.vin_code);
  
              const requestOptions = {
                  method: "POST",
                  body: formdata,
                  redirect: "follow",
              };
  
              fetch(`${process.env.REACT_APP_API_URL}/history/create/`, requestOptions)
                  .then((response) => response.text())
                  .then((result) => console.log(result))
                  .catch((error) => console.log("error", error));
              
              // alert("");
              navigate("/vehicleData");
          } else {
              // Handle other status codes if needed
          }
      } catch (error) {
          // Handle API call errors
          toast.error(`Error while saving work`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
          console.error('Error while saving work:', error);
      }
  };
  
  
  
 
    
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/getallmileages/`)
      .then(response => {
        const options = response.data.map(item => ({ value: item.id, label: item.Mileage }));
        setMileageOptions(options);
      })
      .catch(error => {
        console.error("Error fetching mileage options:", error);
      });
  }, []);
  
  const onChangeValue = (event, key) => {
    const value = event.target.value;
    setDataPost(prevState => ({
        ...prevState,
        [key]: value
    }));
};

const handleMileageChange = (selectedOption) => {
    setSelectedMileage(selectedOption);
    setDataPost(prevState => ({
        ...prevState,
        mileage: selectedOption.value
    }));
};
const onChange = (date) => {
  setValue(date);
  const formattedDate = date.toISOString().slice(0, 10);
  setDataPost(prevState => ({
      ...prevState,
      scheduled_date: formattedDate
  }));
};

  // const onChangeValue = (event, key) => {
  //   const value = event.target.value;
  //   setDataPost({
  //     ...dataPost.work,
  //     [key]: value
  //   });
    
  // }
    
  return (
    <div className="flex">
    <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}  />

    <Header setOpenSidebar={setOpenSidebar} />

    <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
        {/* ADD NEW SERVICE */}
        <div className="px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16">
          <div className="flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14">
            {/* LEFT */}
            <div className="w-full md:w-1/2 flex flex-col self-center">
              {/* IMAGE */}
              <div className=" ">
                  <h3 className="text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold">
                  Add Work
                  </h3>
                </div>
            </div>

            {/* RIGHT */}
            <div className="w-full md:w-1/2 flex flex-col self-center">
              <div className="md:pl-5 xl:pl-[30px]">
                {/* BIKE NAME */}
                <div className="">
                  <h3 className="bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold">
                    {db_data?.model_name}, {db_data?.year},{" "}
                    {db_data?.color}
                  </h3>
                </div>

                

                
          
                          
                        
              </div>
            </div>
          </div>

         

          
          

        
        
         
            {/*  Failure description */}
            <div className="mb-7">
            <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
            {/* {t("Failure_description") } */}
            Customer description
            </h3>

            <textarea 
            // value={dataPost?.Customer_description}
            onChange={(event)=>{
              onChangeValue(event,"customer_description")
            }} 
            className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full" />
          </div>
           




                  
           
         




          <div>
          <div className="mb-7">
        <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
        The receiver of the works description for mechanic
        </h3>
        <textarea
        //  value={dataPost?.works_description}
         onChange={(event)=>{
           onChangeValue(event,"work_description")
         }} 
         
          className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full"
        />
      </div>

     
    </div>

   
        
    <div className="mb-7">
    <div className="flex items-center justify-between">
      {/* LEFT */}
      <div className="flex items-center">
        {/* TITLE */}
        <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
          Maintenance
        </h3>
        {/* INPUT */}
        <div className="ml-4 min-w-[235px] h-[43px] bg-black-500 select">
          <input
            type="text"
            // value={dataPost?.Maintenance}
            onChange={(event)=>{
              onChangeValue(event,"maintenance_description")
            }} 
            placeholder=""
            className="w-full min-h-[45px] text-f_18_l_23 text-white border-[0.5px] border-white-100 rounded-[10px] transition-all duration-300 focus:ring-0 focus:outline-none bg-[#181717] hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 px-2"
          />
        </div>
      </div>
      {/* RIGHT */}
      <div className="pl-12 xl:pl-24">
        {/* Content for the right side */}
      </div>
    </div>
</div>
    <div className="mb-7 flex items-center">
    <div className="flex items-center justify-between">
      {/* LEFT */}
      <div className="flex items-center">
        {/* TITLE */}
        <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold ">
          The current mileage is approx
        </h3>
        {/* SELECT */}
        <div className="ml-4 min-w-[235px] h-[45px] bg-black-500 select">
          <Select
            value={selectedMileage}
            onChange={handleMileageChange}
            options={mileageOptions}
            placeholder=""
            classNames={{
              // classNames for select styles
            }}
          />
        </div>
      </div>
      {/* RIGHT */}
      <div className="pl-12 xl:pl-24">
        {/* Content for the right side */}
      </div>
    </div>
  <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold ">KM</h3>
  <img
    src={info_icon}
    className="h-5 w-5 ml-2 text-gray-600" // Adjust margin-left (ml-2) as needed for spacing
    alt="info-icon"
  />
</div>
<div className="mb-7">
<Calendar
                onChange={onChange}
                value={value}
            />
    </div>
        
        
        
       







          {/* SAVE SERVICE DATA BUTTON */}
          <button
            className="w-[240px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
            onClick={(event)=>{
              saveWork();
              event.target.style.display = 'none';
            }}
            >
            {/* {t("Save_warranty_data") } */}
            Add to work queue
          </button>
          <br/>



          {
   
}

                  
        </div>
      </main>
  </div>
  )
}

export default Addwork