import { takeEvery, call, put } from "redux-saga/effects";
import { SIGNIN_REQUEST, VINN_REQUEST, SIGNUP_REQUEST } from "../actions/types";
import { signIn, vinnIn, signUp, profileData } from "../../api/users";
import {
  userActions,
  notificationActions,
  vinnActions,
  loaderActions,
} from "../actions";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { delay } from "redux-saga/effects";

function* signUpRequest(action) {
  try {
    yield put(loaderActions.showLoader());
    const user = yield call(signUp, action.payload.user);
    const { data } = user;
    action.payload.navigate("/");
    localStorage.setItem("token", data.info);
    yield put(userActions.signUpSuccess(data.info));
  } catch (error) {
    toast.error(`${error?.response?.data?.errors?.non_field_errors}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error.response.data));
    yield put(userActions.signUpSuccess({}));
  } finally {
    yield put(loaderActions.hideLoader());
  }
}

function* signInRequest(action) {
  try {
    yield put(loaderActions.showLoader());

    const ipResponse = yield call(
      axios.get,
      "https://api.ipify.org?format=json"
    );
    const userIp = ipResponse.data.ip;
    yield delay(500);
    const user = yield call(signIn, action.payload.user);
    const { data } = user;

    if (data?.profile?.ip_access && data?.profile?.ip_access !== userIp) {
      console.log("true: ", userIp);
      // Directly show toast for IP address mismatch
      toast.error("IP address mismatch", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      yield put(loaderActions.hideLoader());

      // Optional: You can still throw an error or handle the situation as needed
      return; // Exit the function or handle as needed
    } else {
      localStorage.setItem("token", data.token);

      // const profile = yield call(profileData, data.token);
      localStorage.setItem("profile_image", data?.profile?.image);
      localStorage.setItem("profile", data?.profile?.name);
      localStorage.setItem("profile_id", data?.profile?.id);
      localStorage.setItem("profile_email", data?.profile?.email);
      localStorage.setItem("show_accessories", data?.profile?.show_accessories);
      localStorage.setItem(
        "company_name",
        data?.profile?.company?.company_name
      );
      localStorage.setItem("company_id", data?.profile?.company?.id);
      console.log("else ", userIp);
      // IP address matches or is not set, proceed to dashboard
      yield put(loaderActions.hideLoader());
      action.payload.navigate("/dashboard");
      window.location.reload();
    }
    // action.payload.navigate('/dashboard')
    // window.location.reload();

    yield put(userActions.signInSuccess(data.profile));
  } catch (error) {
    console.log("eroororo==>> ", error);
    toast.error(`${error?.response?.data?.errors?.non_field_errors}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error.response.data));
    yield put(userActions.signInSuccess({}));
    yield put(loaderActions.hideLoader());
  }
}

function* vinnInRequest(action) {
  try {
    yield put(loaderActions.showLoader());

    const number = yield call(vinnIn, action.payload.number?.numb);
    localStorage.setItem("vinnData", JSON.stringify(number));
    console.log("kdsjfkldjfskjdfs", number);
    // localStorage.setItem('token', localStorage.getItem("temptoken"));
    // action.payload.navigate('/Service')
    action.payload.navigate("/vehicleData");

    window.location.reload();
    yield put(vinnActions.vinInSuccess(number.data));
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(notificationActions.addNotification(error?.response?.data));
    yield put(vinnActions.vinInSuccess({}));
  } finally {
    yield put(loaderActions.hideLoader());
  }
}

function* userWatcher() {
  yield takeEvery(SIGNUP_REQUEST, signUpRequest);
  yield takeEvery(SIGNIN_REQUEST, signInRequest);
  yield takeEvery(VINN_REQUEST, vinnInRequest);
}

export { userWatcher };
