import React, { useState } from "react";
import Sidebar from "../components/Common/Sidebar";
import Header from "../components/Common/Header";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const {t}=useTranslation();
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <>
      <div className="flex">
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} activeMe="dashboard"/>

        <Header setOpenSidebar={setOpenSidebar} />

        <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
          {/* DASHBOARD */}
          <div className="pt-10 pb-[10.4375rem] py-[3.0625rem] xl:py-[3.8125rem] px-[26px] md:px-8 xl:px-[3.4375rem]">
            <div className="bg-black-200 pt-[30px] md:pt-9 pl-5 md:pl-10 xl:pl-[3.4375rem] pr-3 md:pr-8 pb-[5.3125rem] xl:pb-[6.375rem] rounded-[25px]">
              {/* TITLE */}
              <div className="mb-4 md:mb-[38px]">
                <h1 className="text-f_45_l_57 font-supremeMedium">
                  Welcome {localStorage.getItem('profile')}
                </h1>
              </div>

              {/* Learn How to use this System */}
              <div className="mb-[18px] md:mb-8 xl:mb-9">
                <h3 className="bg-gradient-text text-f_25_l_32 mb-3 md:mb-2.5">
                  {/* Learn How to use this System */}
                  {t("dasboard_learn")}
                </h3>
                <p className="text-f_18_l_28">
                {t("DESC_USE")}
                </p>
              </div>

              {/* STEPS */}
              <div className="space-y-3 md:space-y-[5px] xl:space-y-[18px] mb-4 md:mb-6 xl:mb-11">
                {/* STEP 1 */}
                <div>
                  <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                  {t("Step_1")}
                  </h3>
                  <p className="text-f_18_l_28">
                  {t("Step_1_desc")}
                  </p>
                </div>

                {/* STEP 2 */}
                <div>
                  <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                  {t("Step_2")}
                  </h3>
                  <p className="text-f_18_l_28">
                  {t("Step_2_desc")}
                  </p>
                </div>

                {/* STEP 3 */}
                <div>
                  <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                  {t("Step_3")}
                  </h3>
                  <p className="text-f_18_l_28">
                  {t("Step_3_desc")}
                  </p>
                </div>
              </div>

              {/* System tutorial videos */}
              
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Dashboard;
// 